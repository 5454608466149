import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { colors } from 'theme'
import SEO from 'components/SEO'
import { Box, Grid } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Container from 'components/Container'
import CtaHero from 'components/CtaHero'
import Footer from 'components/Footer'
import ExternalLink from 'components/Link'
import ImageText from 'routes/About/ImageText'
import bgImage from 'static/images/bg_business.jpg'
import businesses1 from 'static/svg/businesses_1.svg'
import businesses2 from 'static/svg/businesses_2.svg'
import businesses3 from 'static/svg/businesses_3.svg'
import businesses4 from 'static/svg/businesses_4.svg'

const IMAGE_TEXT_CONTENT = [
  {
    title: 'Search for Agencies That Meet All of Your Preferences',
    body:
      'No more relying on Google, word-of-mouth, or chance encounters to find an agency that’s right for your business. Sit back and let Agency Vista’s search engine do all of the legwork for you.',
    image: businesses1,
  },
  {
    title: 'Check Off All the Boxes with Our Search Criteria',
    body:
      'Find tons of agencies that understand your industry, have the optimal team size, can work with your monthly budget, and are based out of your city (or work remotely).',
    image: businesses2,
  },
  {
    title: 'Gain a Clear View of Agency Performance and Reputation',
    body:
      'See which social media profiles an agency manages, how those accounts are performing, and what their clients really think about the agency—all in one place.',
    image: businesses3,
  },
  {
    title: 'Connect with the Click of a Button',
    body:
      'We’re very big fans of not wasting time on things like outdated introductory meetings and phone calls—so we got rid of them. Once you find the agency (or a few agencies) that match your needs, express interest and get connected with a single click.',
    image: businesses4,
  },
]

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const ButtonLink = styled(Button.Primary)`
  display: flex;
  text-decoration: none;
`

const StyledButtonPrimary = styled(Button.Primary)`
  &:hover {
    p {
      color: ${colors.primary};
    }
    background-color: ${colors.white};
  }
`

const StyledButtonReversed = styled(Button.Reversed)`
  &:hover {
    p {
      color: ${colors.white};
    }
    background-color: ${colors.primary};
  }
`

const Businesses = () => (
  <App>
    <SEO
      title="Businesses"
      description="Agency Vista is the new way for brands to find and easily connect with social and creative agencies."
      path="/businesses/"
      image="/img/discover_perfect_social_agency.jpg"
    />
    <Hero>
      <BgImg src={bgImage} />
      <H1 color="white">Marketing agency search platform for businesses</H1>
      <Box mt="m" maxWidth="600px">
        <StyledH3 color="white">
          Agency Vista is the new way for brands to find and easily connect with digital marketing agencies.
        </StyledH3>
      </Box>
      <div>
        <Grid mt="xl" gridTemplateColumns="repeat(2, auto)" gridGap="l">
          <StyledButtonReversed as={ExternalLink} href="/find-agency">
            <Text fontWeight="medium">Find an Agency</Text>
          </StyledButtonReversed>
          <StyledButtonPrimary as={ExternalLink} href="/search">
            <Text fontWeight="medium">Start Browsing</Text>
          </StyledButtonPrimary>
        </Grid>
      </div>
      {/*<Box mt="m">*/}
      {/*  <ButtonLink as="a" href="/search">*/}
      {/*    <Text fontWeight="medium">Search agencies</Text>*/}
      {/*  </ButtonLink>*/}
      {/*</Box>*/}
    </Hero>
    {/* <Sponsors /> */}
    <Container maxWidth="1080px">
      <Box mt="xxl">
        {IMAGE_TEXT_CONTENT.map((content, index) => (
          <Box key={content.title} mb="xxl">
            <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
          </Box>
        ))}
      </Box>
    </Container>
    <Box my="xl" backgroundColor={colors.primary}>
      <Container maxWidth="1080px">
        <CtaHero />
      </Container>
    </Box>
    <Footer />
  </App>
)

export default Businesses
